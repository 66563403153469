import * as React from "react"

import { Container } from "../../components"
import { Category } from "../../section"
import Seo from "../../components/seo"

const MensSkincare = () => (
  <Container>
    <Seo
      title="メンズスキンケア"
      description="肌荒れやしわ・たるみに悩んでいませんか？若いうちにしっかりと正しいスキンケアをしておかないと取り返しのつかないことになります。女性と男性は全く別の生き物なので、同じスキンケアをしても必ずしも同じ効果は得られません。ここでは正しいメンズスキンケア方法やおすすめの商品を紹介します。"
    />
    <Category
      Title="Men's Skin Care"
      SubTitle="メンズスキンケア"
      theCategory="mens-skincare"
    />
  </Container>
)

export default MensSkincare
